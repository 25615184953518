<template>
  <div>
    <div class="about-header">
      <p class="about-title">关于我们</p>
      <p class="about-miaoshu">为用户快速有效率的搭建满意的企业管理平台</p>
    </div>
    <p class="introd_title">关于趣谷</p>
    <div class="about-body">
      <div class="about-body-left">
        <img src="../assets/img/about_body.jpg" alt="" />
      </div>
      <div class="about-body-right">
        <pre>
      苏州趣谷科技有限公司，于2020年，投资2000万元在苏州工业园区设立研发办公室，致力于制造业管理软件快速开发平台的研发与创新。
      趣谷致力于做制造业管理软件的创新者、变革者，通过持续降低个性化定制的开发成本，让管理软件更具管理思想。
      趣谷已经实现了在建筑构建行业、橡胶行业、吹塑行业、镀铝涂布行业、薄膜行业等多个行业的管理软件。趣谷研发团队有着丰富的管理行业、移动办公、系统集成等方面的经验。能够实现需求调研、产品设计、平台研发、产品测试全流程独立实现。
        </pre>
        <pre class="lianxiren">
联系人:王军  18652594266  齐文峰  18921729494</pre
        >
      </div>
    </div>
    <div class="bottom">
      <div class="bottom-body">
        <div class="bottom-title">联系我们</div>
        <div class="record">
          <div class="record-left">
            <div>
              <p class="bottom-title">公司官网</p>
              <p class="bottom-miaoshu">https://www.qugusoft.com</p>
            </div>
            <div>
              <p class="bottom-title">地址</p>
              <p class="bottom-miaoshu">苏州工业园区东平街287号广鸿大厦518室</p>
            </div>
            <div>
              <p class="bottom-title">传真</p>
              <p class="bottom-miaoshu">0512-6625 6646</p>
            </div>
          </div>
          <!-- <div class="record-right"> -->
          <!-- <img src="../assets/img/lianxi.png" alt="" />
            <p>趣谷官方微信公众号</p> -->
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  created() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.about-header {
  width: 100%;
  height: 580px;
  background: url(../assets/img/about.jpg) no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .about-title {
    width: 100%;
    text-align: center;
    font-size: 28px;
    color: #fff;
    opacity: 0.8;
    margin: 0;
  }
  .about-miaoshu {
    font-size: 40px;
    text-align: center;
    padding-top: 10px;
    color: #fff;
    opacity: 1;
    margin: 0;
  }
}
.introd_title {
  height: 60px;
  text-align: center;
  font-size: 60px;
  line-height: 60px;
  color: #000;
  margin: 0;
  font-family: "幼圆";
  margin-top: 30px;
}
.about-body {
  padding: 50px 13% 100px;
  display: flex;
  justify-content: space-between;
  .about-body-left {
    margin-left: 50px;
    img {
      width: 600px;
    }
  }
  .about-body-right {
    pre {
      width: 672px;
      font-size: 16px;
      line-height: 180%;
      color: #636363;
      //使内容自动换行
      white-space: pre-wrap;
      word-wrap: break-word;
      font-family: "幼圆";
    }
    .lianxiren {
      font-family: "幼圆";
    }
  }
}
.bottom {
  .bottom-body {
    width: 100%;
    background: #1b2327;
    padding-top: 20px;
    .bottom-title {
      text-align: center;
      font-size: 20px;
      color: #999;
      margin-bottom: 20px;
    }
    .record {
      width: 60%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .record-left {
        width: 100%;
        // padding-top: 20px;
        color: #999;
        line-height: 25px;
        display: flex;
        justify-content: space-between;
        // &::before {
        //   content: "";
        //   height: 1px;
        //   width: 800px;
        //   background: #333;
        //   display: block;
        //   margin-bottom: 20px;
        // }
        div {
          width: 30%;
          .bottom-title {
            font-size: 16px;
            margin: 0;
            text-align: center;
          }
          .bottom-miaoshu {
            font-size: 14px;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
